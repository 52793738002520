<template>
  <TModal
    :title="!is_edit ? 'Create agency' : 'Agency'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <SSelectCustomer
      label="Representative"
      :prepend="{ id: '', name: this.$t('Select') }"
      prependAll
      class="mb-3"
      :value.sync="input.user_id"
      noCustomLabel
    />
    <TInputText
      label="Agency name"
      class="mb-3"
      :value="input.name"
      @update:value="input.name = $event"
    />
    <TInputText
      label="Tel"
      class="mb-3"
      :value="input.tel"
      @update:value="input.tel = $event"
    />
    <TInputText
      label="Address"
      class="mb-3"
      :value="input.address"
      @update:value="input.address = $event"
    />
    <CRow>
      <CCol col="6">
        <TInputNumber
          label="Latitude"
          class="mb-3"
          :maskOptions="{ decimalLimit: 6 }"
          :value="input.latitude"
          @update:value="input.latitude = $event"
        />
      </CCol>
      <CCol col="6">
        <TInputNumber
          label="Longitude"
          class="mb-3"
          :maskOptions="{ decimalLimit: 6 }"
          :value="input.longitude"
          @update:value="input.longitude = $event"
        />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    agency: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    user_id: "",
    name: "",
    tel: "",
    address: "",
    longitude: null,
    latitude: null,
  },
  data() {
    return {
      key: "agencyModal",
      loading: false,
      checkUser: true,
      data: this.getInput(),
    };
  },
  watch: {
    agency() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.agency) {
        return false;
      }
      return !!this.agency.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.agencies.create", this.input)
        .then(() => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("warehouse.agencies.update", {
          id: this.agency.id,
          attributes: this.input,
        })
        .then(() => {
          this.$store.dispatch("warehouse.agencies.fetch");
          this.key = this.lodash.resetKey("agencyModal");
          this.$emit("update:show", false);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return this.lodash.pick(this.agency, [
        "address",
        "user_id",
        "name",
        "tel",
        "longitude",
        "latitude",
      ]);
    },
  },
};
</script>
