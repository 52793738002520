<template>
  <div>
    <TTableAdvance
      :items="angencies"
      :fields="fields"
      store="warehouse.agencies"
      @click-clear-filter="clearFilter"
      resource="/management/agency"
      creatable
      deletable
      @click-create="showModalCreate = true"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 text-center" col="12">
          <TButtonEdit @click="editItem(item)" />
        </CCol>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <template #tel="{ item }">
        <td>
          <TMessage :content="item.tel" noTranslate />
        </td>
      </template>
      <template #address="{ item }">
        <td>
          <TMessage :content="item.address" noTranslate />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
      <template #user="{ item }">
        <td>
          <TMessage v-if="item.user" :content="item.user.name" noTranslate />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #coordinate="{ item }">
        <td>
          <TMessage v-if="item.latitude" :content="item.latitude" noTranslate>
            <template #suffix> , {{ item.longitude }} </template>
          </TMessage>
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Agency Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>
      <template #name-filter>
        <TInputText
          placeholder="Agency name"
          :value.sync="filter.name"
          @update:value="filterChange"
        />
      </template>
    </TTableAdvance>
    <AgencyModal :show.sync="showModalCreate" />
    <AgencyModal :show.sync="showModalEdit" :agency="detail" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AgencyModal from "./components/AgencyModal.vue";

export default {
  components: { AgencyModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        {
          key: "id",
          label: "Agency Id",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "name",
          label: "Agency",
          _style: "width: 300px; min-width: 300px",
        },
        { key: "tel", label: "Tel", _style: "width: 150px; min-width: 150px" },
        {
          key: "user",
          label: "Representative",
          _style: "width: 200px; min-width: 200px",
        },
        {
          key: "address",
          label: "Address",
          _style: "width: 250px; min-width: 250px",
        },
        {
          key: "coordinate",
          label: "Coordinate",
          _style: "width: 250px; min-width: 250px",
        },
      ],
      showModalCreate: false,
      showModalEdit: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      angencies: "warehouse.agencies.list",
      detail: "warehouse.agencies.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy(filter);
      this.$store.dispatch("warehouse.agencies.apply-query", filterFields);
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
    editItem(item) {
      this.$store.commit("warehouse.agencies.select", item.id);
      this.showModalEdit = true;
    },
  },
};
</script>
